/* global axios */
import ApiClient from './ApiClient';
import store from '../store';
import webhookService from '../services/kanban/webhookService';

class KanbanAPI extends ApiClient {
  constructor() {
    super('kanban_items', { accountScoped: true });
  }

  get accountId() {
    return store.state.auth.currentUser.account_id;
  }

  getItems(funnelId) {
    const params = {
      funnel_id: funnelId || store.getters['funnel/getSelectedFunnel']?.id,
    };

    return this.get('', { params });
  }

  getItem(itemId) {
    return axios.get(`${this.url}/${itemId}`);
  }

  createItem(data) {
    return axios.post(this.url, {
      kanban_item: data,
    });
  }

  updateItem(itemId, data) {
    return axios.put(`${this.url}/${itemId}`, {
      kanban_item: data,
    });
  }

  deleteItem(itemId) {
    return axios.delete(`${this.url}/${itemId}`);
  }

  async moveToStage(itemId, funnelStage) {
    try {
      console.log('KanbanAPI - moveToStage iniciado:', { itemId, funnelStage });

      // Primeiro obtém o item atual para saber o estágio anterior
      const { data: currentItem } = await this.getItem(itemId);
      console.log('KanbanAPI - Item atual:', currentItem);
      const fromStage = currentItem.funnel_stage;

      // Faz a movimentação do item
      await axios.post(`${this.url}/${itemId}/move_to_stage`, {
        funnel_stage: funnelStage,
      });

      // Busca o item atualizado após a movimentação
      const { data: updatedItem } = await this.getItem(itemId);
      console.log('KanbanAPI - Item após movimentação:', updatedItem);

      // Notifica o webhook sobre a mudança de estágio
      await webhookService.notifyStageChange(
        updatedItem,
        fromStage,
        funnelStage
      );

      return updatedItem;
    } catch (error) {
      console.error('Erro ao mover item:', error);
      throw error;
    }
  }

  reorderItems(positions) {
    return axios.post(`${this.url}/reorder`, {
      positions,
    });
  }

  startTimer(itemId) {
    return this.updateItem(itemId, {
      timer_started_at: new Date().toISOString(),
    });
  }

  stopTimer(itemId) {
    return this.updateItem(itemId, {
      timer_started_at: null,
    });
  }

  getItemByConversationId(conversationId) {
    return this.get('', {
      params: {
        'item_details.conversation_id': conversationId,
      },
    });
  }
}

export default new KanbanAPI();
