<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import KanbanAPI from '../../../api/kanban';
import FunnelAPI from '../../../api/funnel';
import KanbanItemForm from '../kanban/components/KanbanItemForm.vue';
import Modal from '../../../components/Modal.vue';

const props = defineProps({
  conversationId: {
    type: [Number, String],
    required: true,
  },
});

const store = useStore();
const kanbanItem = ref(null);
const funnels = ref([]);
const showKanbanForm = ref(false);
const previewData = ref(null);

// Obtém os dados do contato e conversa atual
const currentChat = computed(() => store.getters.getSelectedChat);
const contactName = computed(
  () => currentChat.value?.meta?.sender?.name || 'Sem nome'
);

// Formata a conversa atual para o select
const currentConversation = computed(() => {
  if (!currentChat.value) return null;

  return {
    id: currentChat.value.id,
    meta: currentChat.value.meta,
    messages: currentChat.value.messages || [],
  };
});

// Watch para mudanças no conversationId
watch(
  () => props.conversationId,
  async (newId, oldId) => {
    if (newId && newId !== oldId) {
      await fetchKanbanItem();
    }
  }
);

const currentFunnel = computed(() => {
  const item = previewData.value || kanbanItem.value;
  if (!item?.funnel_id || !funnels.value.length) return null;
  return funnels.value.find(funnel => funnel.id === item.funnel_id);
});

const kanbanStageLabel = computed(() => {
  const item = previewData.value || kanbanItem.value;
  if (!item || !currentFunnel.value?.stages) return null;
  const stage = currentFunnel.value.stages[item.funnel_stage];
  return stage?.name || item.funnel_stage;
});

const stageStyle = computed(() => {
  const item = previewData.value || kanbanItem.value;
  if (!item || !currentFunnel.value?.stages) return {};
  const stage = currentFunnel.value.stages[item.funnel_stage];
  if (!stage?.color) return {};
  return {
    backgroundColor: `${stage.color}20`,
    color: stage.color,
  };
});

const funnelStyle = computed(() => ({
  backgroundColor: '#64748B20',
  color: '#64748B',
}));

// Dados iniciais para o formulário do Kanban
const initialFormData = computed(() => {
  if (kanbanItem.value) return kanbanItem.value;

  const assignedAgent = currentChat.value?.meta?.assignee;
  const priority = currentChat.value?.priority || 'none';

  return {
    title: contactName.value,
    item_details: {
      title: contactName.value,
      conversation_id: props.conversationId,
      agent_id: assignedAgent?.id || null,
      _agent: assignedAgent,
      priority: priority,
    },
    _conversation: currentConversation.value,
  };
});

const fetchFunnels = async () => {
  try {
    const response = await FunnelAPI.get();
    funnels.value = response.data;
  } catch (error) {
    console.error('Erro ao buscar funis:', error);
  }
};

const fetchKanbanItem = async () => {
  try {
    if (!props.conversationId) return;
    const response = await KanbanAPI.getItemByConversationId(
      props.conversationId
    );
    const matchingItem = response.data.find(
      item => item.item_details.conversation_id === props.conversationId
    );
    kanbanItem.value = matchingItem || null;
  } catch (error) {
    console.error('Erro ao buscar item do Kanban:', error);
    kanbanItem.value = null;
  }
};

const handleKanbanFormSave = async () => {
  showKanbanForm.value = false;
  previewData.value = null;
  await fetchKanbanItem();
};

onMounted(async () => {
  await fetchFunnels();
  await fetchKanbanItem();
});
</script>

<template>
  <div class="p-4">
    <div v-if="kanbanItem && currentFunnel" class="flex flex-col gap-2">
      <div class="flex items-center gap-2">
        <span class="text-slate-600 dark:text-slate-300 text-sm">Funil:</span>
        <span
          class="text-xs font-medium px-2 py-0.5 rounded-full"
          :style="funnelStyle"
        >
          {{ currentFunnel.name }}
        </span>
      </div>
      <div class="flex items-center gap-2">
        <span class="text-slate-600 dark:text-slate-300 text-sm">Etapa:</span>
        <span
          class="text-xs font-medium px-2 py-0.5 rounded-full"
          :style="stageStyle"
        >
          {{ kanbanStageLabel }}
        </span>
      </div>
      <div class="flex justify-center mt-2">
        <woot-button
          variant="smooth"
          color-scheme="secondary"
          size="small"
          @click="showKanbanForm = true"
        >
          Atualizar Funil
        </woot-button>
      </div>
    </div>
    <div
      v-else
      class="flex flex-col items-center justify-center gap-3 text-center"
    >
      <p class="text-sm text-slate-500 dark:text-slate-400">
        Nenhum item do Kanban associado
      </p>
      <woot-button
        variant="smooth"
        color-scheme="primary"
        size="small"
        @click="showKanbanForm = true"
      >
        Definir Funil
      </woot-button>
    </div>

    <!-- Modal do Formulário do Kanban -->
    <Modal
      v-model:show="showKanbanForm"
      :on-close="
        () => {
          showKanbanForm = false;
          previewData.value = null;
        }
      "
    >
      <div class="p-6">
        <h3 class="text-lg font-medium mb-4">
          {{ kanbanItem ? 'Editar Item do Kanban' : 'Criar Item do Kanban' }}
        </h3>
        <KanbanItemForm
          v-if="funnels[0]"
          :funnel-id="funnels[0].id"
          stage="lead"
          :initial-data="initialFormData"
          :is-editing="!!kanbanItem"
          :current-conversation="currentConversation"
          @saved="handleKanbanFormSave"
          @close="showKanbanForm = false"
          @update:preview="newData => (previewData.value = newData)"
        />
      </div>
    </Modal>
  </div>
</template>
