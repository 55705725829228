/* global axios */
import CacheEnabledApiClient from './CacheEnabledApiClient';

class FunnelAPI extends CacheEnabledApiClient {
  constructor() {
    super('funnels', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'funnel';
  }

  create(data) {
    return axios.post(this.url, data);
  }

  delete(id) {
    return axios.delete(`${this.url}/${id}`);
  }

  update(id, data) {
    return axios.patch(`${this.url}/${id}`, data);
  }
}

export default new FunnelAPI();
