<template>
  <div class="funnel-selector">
    <woot-button
      variant="clear"
      size="small"
      class="funnel-button"
      :disabled="uiFlags.isFetching"
      @click="toggleDropdown"
    >
      <fluent-icon icon="task" size="16" class="mr-1" />
      <template v-if="uiFlags.isFetching">
        {{ $t('KANBAN.LOADING_FUNNELS') }}
      </template>
      <template v-else>
        {{ selectedFunnel ? selectedFunnel.name : $t('KANBAN.SELECT_FUNNEL') }}
      </template>
      <fluent-icon icon="chevron-down" size="16" class="ml-1" />
    </woot-button>

    <div v-if="isDropdownOpen" class="dropdown-menu">
      <div
        v-for="funnel in funnels"
        :key="funnel.id"
        class="dropdown-item"
        :class="{ active: selectedFunnel?.id === funnel.id }"
        @click="selectFunnel(funnel)"
      >
        {{ funnel.name }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const isDropdownOpen = ref(false);

const funnels = computed(() => store.getters['funnel/getFunnels']);
const selectedFunnel = computed(
  () => store.getters['funnel/getSelectedFunnel']
);
const uiFlags = computed(() => store.getters['funnel/getUIFlags']);

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const selectFunnel = async funnel => {
  await store.dispatch('funnel/setSelectedFunnel', funnel);
  isDropdownOpen.value = false;
};

onMounted(async () => {
  try {
    await store.dispatch('funnel/fetch');
    // Se não houver funil selecionado e existirem funis, seleciona o primeiro
    if (!selectedFunnel.value && funnels.value.length > 0) {
      selectFunnel(funnels.value[0]);
    }
  } catch (error) {
    console.error('Erro ao carregar funis:', error);
  }
});
</script>

<style lang="scss" scoped>
.funnel-selector {
  position: relative;
  display: inline-block;
}

.funnel-button {
  display: flex;
  align-items: center;
  gap: var(--space-micro);
  padding: var(--space-small);
  border: 1px solid var(--color-woot);
  border-radius: var(--border-radius-normal);
  background: var(--white);
  color: var(--color-woot);

  @apply dark:bg-slate-800 dark:border-woot-600 dark:text-woot-500;

  &:hover {
    background: var(--color-background);
    border-color: var(--color-woot);
    opacity: 0.8;

    @apply dark:bg-slate-700 dark:border-woot-500;
  }
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: var(--z-index-dropdown);
  min-width: 200px;
  margin-top: var(--space-micro);
  padding: var(--space-micro);
  background: var(--white);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-normal);
  box-shadow: var(--shadow-dropdown);

  @apply dark:bg-slate-800 dark:border-slate-700;
}

.dropdown-item {
  padding: var(--space-small) var(--space-normal);
  cursor: pointer;
  border-radius: var(--border-radius-small);

  @apply dark:text-slate-100;

  &:hover {
    background: var(--color-background);
    @apply dark:bg-slate-700;
  }

  &.active {
    background: var(--color-background);
    color: var(--color-woot);

    @apply dark:bg-slate-700 dark:text-woot-500;
  }
}
</style>
