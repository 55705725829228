<script setup>
import { ref, computed, nextTick, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import Modal from '../../../../components/Modal.vue';
import KanbanItemForm from './KanbanItemForm.vue';
import FunnelSelector from './FunnelSelector.vue';
import KanbanSettings from './KanbanSettings.vue';
import BulkDeleteModal from './BulkDeleteModal.vue';
import BulkMoveModal from './BulkMoveModal.vue';
import BulkAddModal from './BulkAddModal.vue';
import KanbanAPI from '../../../../api/kanban';
import KanbanFilter from './KanbanFilter.vue';

// Função utilitária de debounce
const debounce = (fn, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), delay);
  };
};

const { t } = useI18n();
const store = useStore();
const emit = defineEmits([
  'filter',
  'settings',
  'item-created',
  'bulk-actions',
  'search',
  'items-updated',
  'switch-view',
]);

const showAddModal = ref(false);
const selectedFunnel = computed(
  () => store.getters['funnel/getSelectedFunnel']
);

const props = defineProps({
  currentStage: {
    type: String,
    default: '',
  },
  searchResults: {
    type: Object,
    default: () => ({ total: 0, stages: {} }),
  },
  columns: {
    type: Array,
    default: () => [],
  },
  activeFilters: {
    type: Object,
    default: null,
  },
});

const showSettingsModal = ref(false);

const showSearchInput = ref(false);
const searchQuery = ref('');
const showResultsDetails = ref(false);

const showBulkActions = ref(false);

const bulkActions = [
  {
    id: 'add',
    label: t('KANBAN.BULK_ACTIONS_OPTIONS.ADD'),
    icon: 'add',
  },
  {
    id: 'move',
    label: t('KANBAN.BULK_ACTIONS_OPTIONS.MOVE'),
    icon: 'arrow-right',
  },
  {
    id: 'delete',
    label: t('KANBAN.BULK_ACTIONS_OPTIONS.DELETE'),
    icon: 'delete',
  },
];

const showBulkDeleteModal = ref(false);
const showBulkMoveModal = ref(false);
const showBulkAddModal = ref(false);
const kanbanItems = ref([]);

const showSettingsDropdown = ref(false);

const settingsOptions = [
  {
    id: 'manage_funnels',
    label: t('KANBAN.SETTINGS_OPTIONS.MANAGE_FUNNELS'),
    icon: 'task',
  },
  {
    id: 'kanban_settings',
    label: t('KANBAN.SETTINGS_OPTIONS.KANBAN_SETTINGS'),
    icon: 'settings',
  },
];

const handleSettingsOption = option => {
  if (option.id === 'kanban_settings') {
    showSettingsModal.value = true;
  } else if (option.id === 'manage_funnels') {
    emit('switch-view', 'funnels');
  }
  showSettingsDropdown.value = false;
};

const handleSettingsClick = () => {
  showSettingsDropdown.value = !showSettingsDropdown.value;
};

const fetchKanbanItems = async () => {
  try {
    const currentFunnel = store.getters['funnel/getSelectedFunnel'];

    if (!currentFunnel?.id) {
      console.error('Nenhum funil selecionado');
      return;
    }

    const response = await KanbanAPI.getItems(currentFunnel.id);

    kanbanItems.value = response.data.map(item => ({
      id: item.id,
      title: item.item_details.title || '',
      description: item.item_details.description || '',
      priority: item.item_details.priority || 'none',
      assignee: item.item_details.assignee || null,
      position: item.position,
      funnel_stage: item.funnel_stage,
      item_details: item.item_details,
      custom_attributes: item.custom_attributes || {},
      createdAt: new Date(item.created_at).toLocaleDateString(),
    }));
  } catch (error) {
    console.error('Erro ao carregar itens do Kanban:', error);
  }
};

// Watch para atualizar os itens quando o funil mudar
watch(
  () => store.getters['funnel/getSelectedFunnel'],
  async newFunnel => {
    if (newFunnel) {
      await fetchKanbanItems();
    }
  }
);

// Carregar itens quando o componente for montado
onMounted(async () => {
  const selectedFunnel = store.getters['funnel/getSelectedFunnel'];
  if (selectedFunnel) {
    await fetchKanbanItems();
  }
});

const handleBulkActions = () => {
  showBulkActions.value = !showBulkActions.value;
};

const selectBulkAction = action => {
  if (action.id === 'delete') {
    showBulkDeleteModal.value = true;
  } else if (action.id === 'move') {
    showBulkMoveModal.value = true;
  } else if (action.id === 'add') {
    showBulkAddModal.value = true;
  }
  showBulkActions.value = false;
};

const showFilterModal = ref(false);

const handleFilterApply = filters => {
  emit('filter', filters);
  showFilterModal.value = false;
};

const handleFilter = () => {
  showFilterModal.value = true;
};

const handleSettings = () => {
  showSettingsModal.value = true;
};

const handleCloseSettings = () => {
  showSettingsModal.value = false;
};

const handleAdd = () => {
  if (!selectedFunnel.value?.id) {
    // TODO: Mostrar mensagem de erro para selecionar um funil primeiro
    console.error('Nenhum funil selecionado');
    return;
  }
  showAddModal.value = true;
};

const handleItemCreated = async item => {
  emit('item-created', item);
  showAddModal.value = false;
  await fetchKanbanItems();
};

const handleSearch = () => {
  showSearchInput.value = !showSearchInput.value;
  if (showSearchInput.value) {
    // Aguarda o DOM atualizar para focar no input
    nextTick(() => {
      document.getElementById('kanban-search').focus();
    });
  } else {
    // Limpa a busca ao fechar
    searchQuery.value = '';
    emit('search', '');
  }
};

// Watch para manter o input visível enquanto houver busca
watch(searchQuery, newValue => {
  if (newValue) {
    showSearchInput.value = true;
  }
});

const onSearch = debounce(e => {
  const query = e.target.value;
  searchQuery.value = query;
  emit('search', query);
}, 500);

const handleBulkDelete = async selectedIds => {
  try {
    // Exclui cada item usando o KanbanAPI
    await Promise.all(selectedIds.map(id => KanbanAPI.deleteItem(id)));

    showBulkDeleteModal.value = false;
    await fetchKanbanItems();
    emit('items-updated');
  } catch (error) {
    console.error('Erro ao excluir itens:', error);
  }
};

const handleBulkMove = async ({ itemIds, stageId }) => {
  try {
    await Promise.all(itemIds.map(id => KanbanAPI.moveToStage(id, stageId)));

    showBulkMoveModal.value = false;
    await fetchKanbanItems();
    emit('items-updated');
  } catch (error) {
    console.error('Erro ao mover itens:', error);
  }
};

const handleBulkItemsCreated = async items => {
  showBulkAddModal.value = false;
  await fetchKanbanItems();
  emit('items-updated');
};

// Watch para atualizar quando os itens do KanbanTab mudarem
watch(
  () => store.getters['kanban/getItems'],
  async () => {
    await fetchKanbanItems();
  }
);

// Função para obter a cor da etapa
const getStageColor = stageName => {
  const column = props.columns.find(col => col.title === stageName);
  return column?.color ? `${column.color}` : '#64748B';
};

const handleMessageTemplates = () => {
  emit('switch-view', 'messageTemplates');
};

// Computed para contar filtros ativos
const activeFiltersCount = computed(() => {
  if (!props.activeFilters) return 0;

  let count = 0;
  if (props.activeFilters.priority?.length) count++;
  if (props.activeFilters.value?.min || props.activeFilters.value?.max) count++;
  if (props.activeFilters.agent_id) count++;
  if (props.activeFilters.date?.start || props.activeFilters.date?.end) count++;

  return count;
});
</script>

<template>
  <header class="kanban-header">
    <div class="header-left">
      <funnel-selector />
      <div class="search-container" :class="{ 'is-active': showSearchInput }">
        <woot-button variant="clear" size="small" @click="handleSearch">
          <fluent-icon icon="search" size="16" />
        </woot-button>
        <div v-show="showSearchInput" class="search-input-wrapper">
          <input
            id="kanban-search"
            v-model="searchQuery"
            type="search"
            class="search-input"
            :placeholder="t('SEARCH.INPUT_PLACEHOLDER')"
            @input="onSearch"
            @blur="!searchQuery && (showSearchInput = false)"
          />
          <div
            v-if="searchQuery && searchResults.total > 0"
            class="search-results-tags"
          >
            <div class="search-results-tag">
              {{ searchResults.total }} resultado(s)
            </div>
            <div
              v-for="(count, stageName) in searchResults.stages"
              :key="stageName"
              class="search-results-tag"
              :style="{ backgroundColor: getStageColor(stageName) }"
            >
              <span class="stage-name">{{ stageName }}</span>
              <span class="count-badge">{{ count }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-right">
      <div class="bulk-actions-selector">
        <woot-button variant="link" size="small" @click="handleBulkActions">
          <fluent-icon icon="list" size="16" class="mr-1" />
          {{ t('KANBAN.BULK_ACTIONS') }}
          <fluent-icon icon="chevron-down" size="16" class="ml-1" />
        </woot-button>

        <div v-if="showBulkActions" class="dropdown-menu">
          <div
            v-for="action in bulkActions"
            :key="action.id"
            class="dropdown-item"
            @click="selectBulkAction(action)"
          >
            <fluent-icon :icon="action.icon" size="16" class="mr-2" />
            {{ action.label }}
          </div>
        </div>
      </div>
      <woot-button
        variant="clear"
        size="small"
        class="message-templates-btn"
        @click="handleMessageTemplates"
      >
        <fluent-icon icon="document" size="16" class="mr-1" />
        {{ t('KANBAN.MESSAGE_TEMPLATES.TITLE') }}
      </woot-button>
      <woot-button
        variant="clear"
        color-scheme="secondary"
        class="relative"
        @click="$emit('filter')"
      >
        <fluent-icon icon="filter" />
        <span v-if="activeFiltersCount > 0" class="filter-badge">
          {{ activeFiltersCount }}
        </span>
      </woot-button>
      <woot-button
        variant="smooth"
        color-scheme="primary"
        size="small"
        class="add-item-btn"
        @click="handleAdd"
      >
        <fluent-icon icon="add" size="16" class="mr-1" />
      </woot-button>
      <div class="settings-selector">
        <woot-button variant="clear" size="small" @click="handleSettingsClick">
          <fluent-icon icon="more-vertical" size="16" />
        </woot-button>

        <div v-if="showSettingsDropdown" class="dropdown-menu">
          <div
            v-for="option in settingsOptions"
            :key="option.id"
            class="dropdown-item"
            @click="handleSettingsOption(option)"
          >
            <fluent-icon :icon="option.icon" size="16" class="mr-2" />
            {{ option.label }}
          </div>
        </div>
      </div>
    </div>

    <Modal v-model:show="showAddModal" :on-close="() => (showAddModal = false)">
      <div class="p-6">
        <h3 class="text-lg font-medium">
          {{ t('KANBAN.ADD_ITEM') }}
        </h3>
        <KanbanItemForm
          v-if="selectedFunnel"
          :funnel-id="selectedFunnel.id"
          :stage="currentStage"
          @saved="handleItemCreated"
          @close="showAddModal = false"
        />
        <div v-else class="text-center text-red-500">
          {{ t('KANBAN.ERRORS.NO_FUNNEL_SELECTED') }}
        </div>
      </div>
    </Modal>

    <KanbanSettings :show="showSettingsModal" @close="handleCloseSettings" />

    <Modal
      v-model:show="showBulkDeleteModal"
      :on-close="() => (showBulkDeleteModal = false)"
    >
      <BulkDeleteModal
        :items="kanbanItems"
        @close="showBulkDeleteModal = false"
        @confirm="handleBulkDelete"
      />
    </Modal>

    <Modal
      v-model:show="showBulkMoveModal"
      :on-close="() => (showBulkMoveModal = false)"
    >
      <BulkMoveModal
        :items="kanbanItems"
        @close="showBulkMoveModal = false"
        @confirm="handleBulkMove"
      />
    </Modal>

    <Modal
      v-model:show="showBulkAddModal"
      :on-close="() => (showBulkAddModal = false)"
    >
      <BulkAddModal
        :current-stage="currentStage"
        @close="showBulkAddModal = false"
        @items-created="handleBulkItemsCreated"
      />
    </Modal>

    <KanbanFilter
      :show="showFilterModal"
      @close="showFilterModal = false"
      @apply="handleFilterApply"
    />
  </header>
</template>

<style lang="scss" scoped>
.kanban-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-normal);
  border-bottom: 1px solid var(--color-border);

  @apply dark:border-slate-800;
}

.header-left {
  display: flex;
  align-items: center;
  gap: var(--space-normal);
}

.header-right {
  display: flex;
  align-items: center;
  gap: var(--space-small);
}

.search-container {
  @apply flex items-center relative;

  &.is-active {
    @apply bg-slate-50 dark:bg-slate-800 rounded-lg;

    .search-input-wrapper {
      width: auto;
      min-width: 300px;
      @apply flex items-center gap-2;
    }
  }
}

.search-input-wrapper {
  @apply overflow-hidden transition-all duration-200;
  width: 0;
}

.search-input {
  @apply h-8 px-2 m-0 bg-transparent border-none outline-none text-sm text-slate-800 dark:text-slate-100;

  &:focus {
    @apply outline-none ring-0;
  }

  &::placeholder {
    @apply text-slate-500;
  }
}

.bulk-actions-selector {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: var(--z-index-dropdown);
  min-width: 200px;
  margin-top: var(--space-micro);
  padding: var(--space-micro);
  background: var(--white);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-normal);
  box-shadow: var(--shadow-dropdown);

  @apply dark:bg-slate-800 dark:border-slate-700;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: var(--space-small) var(--space-normal);
  cursor: pointer;
  border-radius: var(--border-radius-small);

  @apply dark:text-slate-100;

  &:hover {
    background: var(--color-background);
    @apply dark:bg-slate-700;
  }
}

.settings-selector {
  position: relative;
  display: inline-block;

  .dropdown-menu {
    right: 0;
    left: auto;
  }
}

.search-results-tags {
  @apply flex items-center gap-1 flex-wrap;
}

.search-results-tag {
  @apply flex items-center px-2 py-0.5 text-xs font-medium rounded-full
    bg-woot-500 text-white whitespace-nowrap gap-1;

  &:first-child {
    @apply bg-slate-600;
  }

  .stage-name {
    @apply text-[10px];
  }

  .count-badge {
    @apply bg-white/20 px-1.5 rounded-full text-[10px] min-w-[18px] text-center;
  }
}

.message-templates-btn {
  @apply border border-dashed border-woot-500 text-woot-500 hover:bg-woot-50 
    dark:hover:bg-woot-800/20 transition-colors;

  &:hover {
    @apply border-woot-600 text-woot-600;
  }
}

.add-item-btn {
  @apply bg-woot-500 text-white p-2;

  .mr-1 {
    @apply m-0;
  }
}

.filter-badge {
  @apply absolute -top-1 -right-1 min-w-[16px] h-4 px-1
    flex items-center justify-center
    text-[10px] font-medium
    bg-woot-500 text-white
    rounded-full;
}
</style>
