<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Modal from '../../../../components/Modal.vue';
import FunnelForm from './FunnelForm.vue';

const { t } = useI18n();
const emit = defineEmits(['switch-view', 'funnel-created']);

const showNewFunnelModal = ref(false);

const handleBack = () => {
  emit('switch-view', 'kanban');
};

const handleFunnelCreated = funnel => {
  showNewFunnelModal.value = false;
  emit('funnel-created', funnel);
};
</script>

<template>
  <header class="funnels-header">
    <div class="flex items-center justify-between">
      <div class="flex items-center gap-4">
        <woot-button variant="clear" size="small" @click="handleBack">
          <fluent-icon icon="chevron-left" size="16" />
        </woot-button>
        <h1 class="text-2xl font-medium">{{ t('KANBAN.FUNNELS.TITLE') }}</h1>
      </div>
      <woot-button
        variant="primary"
        size="small"
        @click="showNewFunnelModal = true"
      >
        <fluent-icon icon="add" size="16" class="mr-2" />
        {{ t('KANBAN.FUNNELS.ADD') }}
      </woot-button>
    </div>

    <Modal
      v-model:show="showNewFunnelModal"
      :on-close="() => (showNewFunnelModal = false)"
      class="funnel-modal"
    >
      <div class="p-6">
        <h3 class="text-lg font-medium mb-4">
          {{ t('KANBAN.FUNNELS.NEW') }}
        </h3>
        <FunnelForm
          @saved="handleFunnelCreated"
          @close="showNewFunnelModal = false"
        />
      </div>
    </Modal>
  </header>
</template>

<style lang="scss" scoped>
.funnels-header {
  padding: var(--space-normal);
  border-bottom: 1px solid var(--color-border);
  background: var(--white);

  @apply dark:border-slate-800 dark:bg-slate-900;

  h1 {
    @apply dark:text-slate-100;
  }
}

:deep(.funnel-modal) {
  .modal-container {
    @apply max-w-[1024px] w-[90vw] dark:bg-slate-900;

    h3 {
      @apply dark:text-slate-100;
    }
  }
}
</style>
